import * as React from 'react';
import { ThemeProvider } from '@fluentui/react-theme-provider';
import GetStarted from './GetStarted';

interface IHostnameConfiguratorContentGetStartedProps {
  hostname: string,
  busyValidatingHostname: boolean,
  hostnameIsInvalid: boolean | null,
  hostnameIsValid: boolean | null,
  onHostnameChange: (newHostname: string) => void,
  onOpenClicked: () => void
}

export default class HostnameConfiguratorContentGetStarted extends React.PureComponent<IHostnameConfiguratorContentGetStartedProps> {
  render() {
    return (
      <ThemeProvider>
        <GetStarted
          hostname={this.props.hostname}
          busyValidatingHostname={this.props.busyValidatingHostname}
          hostnameIsInvalid={this.props.hostnameIsInvalid}
          hostnameIsValid={this.props.hostnameIsValid}
          onHostnameChange={this.props.onHostnameChange}
          onOpenClicked={this.props.onOpenClicked}
        />
      </ThemeProvider>
    );
  }
}
