import * as React from 'react';
import { Spinner, SpinnerType } from '@fluentui/react';

export default class InitializationInterstitial extends React.PureComponent<{}, {}> {
  render() {
    const logo = 'assets/YesplanLogo-80x80.png';
    const title = 'Yesplan Dataviews';

    return ([
      <section key='section' className='ms-welcome__progress ms-u-fadeIn500'>
        <img width='80' height='80' src={logo} alt={title} title={title} />
        <h1 className='u-text-title'>{title}</h1>
        <Spinner
          type={SpinnerType.large}
          label='Initializing Excel Add-in …'
          styles={{ label: 'u-text-body', screenReaderText: 'u-text-body' }}
        />
      </section>
    ]);
  }
}
