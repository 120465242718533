import * as React from 'react';
import InitializationInterstitial from './InitializationInterstitial';
import HostnameConfigurator from './HostnameConfigurator';
import { bind, validateHostname } from '../common';

interface AppState {
  isOfficeInitialized: boolean,
  yesplanHostname: string
}

const documentStorageSettingHostname = 'DocumentStorageYesplanHostname';
const localStorageItemHostname = 'LocalStorageYesplanHostname';

export default class App extends React.Component<{}, AppState> {

  constructor(props, context) {
    super(props, context);

    this.state = {
      isOfficeInitialized: false,
      yesplanHostname: ''
    };
  }

  componentDidMount() {
    this.initializeOfficeAndYesplanHostname();
  }

  async initializeOfficeAndYesplanHostname() {
    await Office.onReady();
    Excel.run(async (context) => {
      const setting = context.workbook.settings.getItemOrNullObject(documentStorageSettingHostname);
      setting.load('value');
      await context.sync();
      let yesplanHostname = (setting.isNullObject) ? '' : setting.value;
      if (!yesplanHostname) {
        yesplanHostname = localStorage.getItem(localStorageItemHostname) || '';
        if (yesplanHostname) {
          context.workbook.settings.add(documentStorageSettingHostname, yesplanHostname);
          await context.sync();
        }
      }
      validateHostname(
        yesplanHostname,
        () => {},
        (valid) => { this.setState({ isOfficeInitialized: true, yesplanHostname: valid ? yesplanHostname : '' }); }
      );
    });
  }

  setHostname(newHostname) {
    return Excel.run(async (context) => {
      context.workbook.settings.add(documentStorageSettingHostname, newHostname);
      await context.sync();
      localStorage.setItem(localStorageItemHostname, newHostname);
      this.setState({ yesplanHostname: newHostname });
    });
  }

  render() {
    if (!this.state.isOfficeInitialized) {
      return <InitializationInterstitial/>;
    }

    if (this.state.yesplanHostname.length === 0) {
      return (
        <HostnameConfigurator
          setHostname={bind(this.setHostname, this)} />);
    }

    const { yesplanHostname } = this.state;
    const sourceURL = 'https://' + yesplanHostname + '/resources-exceladdin/exceladdin_taskpane_loader.js';

    (window.location as any) = `/assets/loader.html?url=${encodeURIComponent(sourceURL)}`;
    return null;
  }
}
