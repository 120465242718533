import * as React from 'react';
import * as _ from 'lodash';
import HostnameConfiguratorContentGetStarted from './HostnameConfiguratorContentGetStarted';
import { bind, validateHostname } from '../common';

export interface HostnameConfiguratorProps {
  setHostname(newHostname: string): void;
}

export interface HostnameConfiguratorState {
  hostname: string;
  doingValidationRequest: boolean;
  didValidationRequest: boolean;
  valid: boolean | null;

  validateHostnameDebounced: any;
}

export default class HostnameConfigurator extends React.Component<HostnameConfiguratorProps, HostnameConfiguratorState> {
  constructor(props, context) {
    super(props, context);

    this.state = {
      hostname: '',
      doingValidationRequest: false,
      didValidationRequest: false,
      valid: null,

      validateHostnameDebounced: _.debounce(
        (newHostname) => {
          if (this.state.hostname === newHostname) {
            validateHostname(
              newHostname,
              () => { this.setState({ doingValidationRequest: true }); },
              (valid) => {
                if (this.state.hostname === newHostname)
                  this.setState({ doingValidationRequest: false, didValidationRequest: true, valid }); 
              }
            );
          }
        },
        1000)
    };
  }

  handleHostnameChange(newHostname: string) {
    this.setState({ hostname: newHostname, doingValidationRequest: false, didValidationRequest: false, valid: null });
    if (newHostname.length > 0) {
      this.state.validateHostnameDebounced(newHostname);
    }
  }

  handleOpenClicked() {
    this.props.setHostname(this.state.hostname);
  }

  render() {
    const contentProps = {
      hostname: this.state.hostname,
      busyValidatingHostname: this.state.doingValidationRequest && !this.state.didValidationRequest,
      hostnameIsInvalid: this.state.didValidationRequest && !this.state.valid,
      hostnameIsValid: this.state.didValidationRequest && this.state.valid,
      onHostnameChange: bind(this.handleHostnameChange, this),
      onOpenClicked: bind(this.handleOpenClicked, this)
    };
    return (<HostnameConfiguratorContentGetStarted {...contentProps} />);
  }
}
