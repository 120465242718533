import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { AppContainer } from 'react-hot-loader';
import { initializeIcons } from '@fluentui/react/lib/Icons';

import App from './components/App';

import './styles.less';
import '@fluentui/react/dist/css/fabric.min.css';
import './styles.css';

initializeIcons();

const render = (Component) => {
    ReactDOM.render(
        <AppContainer>
            <Component />
        </AppContainer>,
        document.getElementById('container')
    );
};

render(App);

if ((module as any).hot) {
    (module as any).hot.accept('./components/App', () => {
        const NextApp = require('./components/App').default;
        render(NextApp);
    });
}
