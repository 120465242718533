import bind = require("memoize-bind");
import memoizeWeak = require("memoize-weak");
import * as request from 'superagent';

export { bind, memoizeWeak };

export const withDropArguments = memoizeWeak(<R,>(fn: () => R) => () => fn());

export function validateHostname(hostname, onValidationRequest, onValidated) {
  if (hostname.match(/^([a-z0-9-]+\.yesplan\.(be|nl|app)|localhost:[0-9]+)$/)) {
    onValidationRequest();
    request
      .get('https://' + hostname + '/YesplanExcelAddInHostnameCheck')
      .timeout(10000)
      .then(response => {
        onValidated(response.status === 200);
      })
      .catch(_error => {
        onValidated(false);
      });
  } else {
    onValidated(false);
  }
}
