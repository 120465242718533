import * as React from 'react';
import { Button, ButtonType, MessageBar, MessageBarType, Spinner, SpinnerSize, TextField } from '@fluentui/react';
import { FormattedMessage } from 'react-intl';
import Header from './GetStarted/Header';
import HeroList from './GetStarted/HeroList';
import { withDropArguments } from '../common';
import './GetStarted/GetStartedStyle.css';

interface IGetStartedProps {
  hostname: string,
  busyValidatingHostname: boolean,
  hostnameIsInvalid: boolean | null,
  hostnameIsValid: boolean | null,
  onHostnameChange: (newHostname: string) => void,
  onOpenClicked: () => void
}

export default class GetStarted extends React.PureComponent<IGetStartedProps> {
  render() {
    const urlManual = 'https://manual.yesplan.be/en/integrations/excel-add-in/';
    const urlWebsite = 'https://yesplan.be/en';
    const listItems = [
      {
        key: 'item1',
        icon: 'Refresh',
        primaryText: 'Load Yesplan dataviews in Excel as tables.'
      },
      {
        key: 'item2',
        icon: 'Chart',
        primaryText: 'Use the Yesplan data in formulas, pivot tables, charts…'
      },
      {
        key: 'item3',
        icon: 'Upload',
        primaryText: 'Send values of custom data fields back to Yesplan.'
      },
    ];

    const invalidHostname = (
      <FormattedMessage
        id='invalidHostname'
        defaultMessage='The URL seems to be incorrect. Please check and try again.' />
    );

    return (
      <>
        <Header logo="assets/YesplanLogo-64x64.png" logoWidth={64} logoHeight={64} title="Yesplan" message="Welcome" />
        <HeroList items={listItems}>
          <div className="ms-welcome__manual">
            Questions? <a target='_blank' href={urlManual}>Read our manual</a>!
          </div>
          <TextField
            value={this.props.hostname}
            errorMessage={this.props.hostnameIsInvalid ? invalidHostname : ''}
            label='First, enter the URL of your Yesplan installation:'
            placeholder='[name-of-organization].yesplan.be'
            onChange={(_event, newHostname) => { this.props.onHostnameChange(newHostname ?? ''); }}
            onRenderInput={(inputProps, defaultRender) => {
              return (
                <>
                  { defaultRender!(inputProps) }
                  { (this.props.busyValidatingHostname)
                    ? (
                      <Spinner
                        size={SpinnerSize.small}
                        styles={{ root: { marginLeft: '0.5em', marginRight: '0.5em' } }}
                      />)
                    : null
                  }
                </>
              );
            }}
          />
          <Button
            className="ms-welcome__action ms-Button--hero"
            disabled={!this.props.hostnameIsValid}
            buttonType={ButtonType.hero}
            onClick={withDropArguments(this.props.onOpenClicked)}
          >
            Connect
          </Button>
          <MessageBar messageBarType={MessageBarType.info}>
            You need a Yesplan installation with an activated Excel Add-in module.
            Not a customer?
            <a target='_blank' href={urlWebsite}>
              Learn more about us
            </a>
            .
          </MessageBar>
        </HeroList>
      </>
    );
  }
}
