import * as React from "react";

export interface HeroListItem {
  key: string,
  icon: string;
  primaryText: React.ReactNode;
}

export interface HeroListProps {
  message?: string;
  items: HeroListItem[];
}

export default class HeroList extends React.PureComponent<HeroListProps> {
  render() {
    const { children, items, message } = this.props;

    const listItems = items.map((item) => (
      <li className="ms-ListItem" key={item.key}>
        <i className={`ms-Icon ms-Icon--${item.icon}`} />
        <span className="ms-font-m ms-fontColor-neutralPrimary">{item.primaryText}</span>
      </li>
    ));
    return (
      <main className="ms-welcome__main">
        {
          (message)
            ? <h2 className="ms-font-xl ms-fontWeight-semilight ms-fontColor-neutralPrimary ms-u-slideUpIn20">{message}</h2>
            : null
        }
        <ul className="ms-List ms-welcome__features ms-u-slideUpIn10">{listItems}</ul>
        {children}
      </main>
    );
  }
}
